import {Datagrid, DateField, FunctionField, List, ListProps, ShowButton} from 'react-admin'
import {useChiefAdmin, useNotRMAdmin, useSuperAdmin} from '../../../hooks/common'
import {SupplyRequestListFilters} from './filters'
import {SupplyRequestListActions} from './actions'
import {useCallback} from 'react'
import {ClipboardTextField} from '../../../components/common/clipboard-text-field/clipboard-text-field'
import {SupplyRequests} from '../../../models/supply-requests'

export const SupplyRequestList = (props: ListProps): JSX.Element => {
    const {exporter} = props
    const isNotRmAdmin = useNotRMAdmin()
    const isChiefAdmin = useChiefAdmin()
    const isSuperAdmin = useSuperAdmin()
    const toShow = useCallback(() => isNotRmAdmin && 'show', [isNotRmAdmin])

    return (
        <List
            sx={{height: 'inherit', overflow: 'hidden'}}
            exporter={(isSuperAdmin || isChiefAdmin) && exporter}
            sort={{field: 'createdAt', order: 'DESC'}}
            filters={<SupplyRequestListFilters />}
            actions={<SupplyRequestListActions />}
            title="Запросы в Снабжение">
            <Datagrid rowClick={toShow} bulkActionButtons={false}>
                <ClipboardTextField source="taskId" label="Номер задачи" />
                <ClipboardTextField source="windrawNumber" label="Номер заказа" />
                <FunctionField source="status" label="Статус" render={SupplyRequests.getSupplyRequestStateName} />
                <DateField source="createdAt" showTime label="Дата создания" />
                <DateField source="statusChangedDate" showTime label="Дата обновления" />
                {isNotRmAdmin && <ShowButton />}
            </Datagrid>
        </List>
    )
}
