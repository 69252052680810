import simpleRestProvider from 'ra-data-simple-rest'
import {API_ADMIN, API_BACKEND} from '../consts/const'
import {DataProvider} from 'react-admin'
import {request, RequestMethods} from '../api/request'
import {User} from '../models/user'
import {
    API_ALERTS,
    API_ALERTS_CHANGE,
    API_AMEGA_EMPLOYEES_ADD,
    API_AMEGA_EMPLOYEES_CHANGE,
    API_AMPQ_RESTART,
    API_AUDIT_EVENTS_TYPES,
    API_AVAILABLE_RESTORE_ITEM,
    API_COMPLETE_ORDER,
    API_DEALERS_CHANGE_PAYMENT_TYPE,
    API_DEALERS_REFUND,
    API_DEALERS_UNDO_REFUND,
    API_DEALERS_UPDATE_INFO,
    API_FILIALS_CHANGE_CITY,
    API_FILIALS_CHANGE_IMPOST,
    API_FILIALS_CHANGE_VILATERM,
    API_FULL_RECALC_USER_PARAMS,
    API_ORDERS_AUTOUNBOOK,
    API_ORDERS_BOOK_IN_ERP,
    API_ORDERS_BOOKING,
    API_ORDERS_COPY_TO_DEV,
    API_ORDERS_COPY_TO_PROD,
    API_ORDERS_CREATE_AT_1C,
    API_ORDERS_CREATE_AT_WINDRAW_AND_1C,
    API_ORDERS_FORCED_RECALC,
    API_ORDERS_FULL_RECALC,
    API_ORDERS_PRODUCTS_SEPARATION_STEP,
    API_ORDERS_RECALC,
    API_ORDERS_RESTORE,
    API_ORDERS_RESTORE_STATUS,
    API_ORDERS_SET_DELIVERY_ADDRESS,
    API_ORDERS_SET_DIRECT_SHIPPING,
    API_ORDERS_SET_DISCOUNTS,
    API_ORDERS_SET_GOST,
    API_ORDERS_SET_GOST_MARK,
    API_ORDERS_SET_HANDLE_PARAMS,
    API_ORDERS_SET_SHIPPING_COST,
    API_ORDERS_SET_SHIPPING_DATE,
    API_ORDERS_SET_SHIPPING_TYPE,
    API_ORDERS_SET_SHIPPING_WAREHOUSE,
    API_ORDERS_SET_SHIPPING_WEEKDAYS,
    API_ORDERS_SET_TAGS,
    API_ORDERS_UNBOOK,
    API_ORDERS_UNBOOK_AT_WINDRAW,
    API_PARTNER_CHANGE_CITY,
    API_PARTNER_CHANGE_DISCOUNT_VISIBILITY,
    API_PARTNER_CHANGE_GOST_ENABLED,
    API_PARTNER_CHANGE_MAIN_DEALER,
    API_PARTNER_CHANGE_METAL_IMPOST_ENABLED,
    API_PARTNER_CHANGE_SUB_DEALER_ENABLED,
    API_PARTNER_CHANGE_VILATERM_ENABLED,
    API_PARTNER_DELETE_CLIENT,
    API_PARTNER_DISCOUNT_CHANGE,
    API_PARTNER_UPDATE_INFO,
    API_PROFILE_UPD_TOKENS,
    API_PROMO_CODE_CONFIRM,
    API_REQUEST_SUPPLY,
    API_RESOURCE_ITEM,
    API_UPDATE_MODEL_USER_PARAMS,
    API_UPDATE_TEMPLATES_USER_PARAMS,
    API_USERS_REGIONAL_MANAGER,
    API_USERS_UPDATE_INFO,
    API_USERS_UPDATE_PASSWORD,
    API_WINDRAW_ORDER_CREATE_START,
    API_WINDRAW_SERVICES_RESTART,
    API_WINDRAW_START_DB_UPDATE,
    API_WINDRAW_STOP_SERVICE
} from '../consts/api'
import {AccountUser, ISupportAccount} from '../models/account_user'
import {AdditionalDiscounts} from '../models/additional-discounts'
import {Partner} from '../models/partner'
import {City} from '../models/city'
import {Order} from '../models/order'
import {RecalcOrderRequest} from '../models/recalc-order-request'
import {HandleParamsRequest} from '../models/handle-params-request'
import {Dealer} from '../models/dealer'
import {Refund} from '../models/refund'
import {Alert} from '../models/alert'
import {AmegaEmployee} from '../models/amega-employee'
import {Discount} from '../models/discount'
import {Tags} from '../models/tags'
import {Address} from '../models/address'

const adminDataProvider = simpleRestProvider(API_ADMIN, request.apiFetch)
const backendDataProvider = simpleRestProvider(API_BACKEND, request.apiFetch)

const backendResources = ['amqphandlers']

export const dataProvider: DataProvider = {
    ...backendDataProvider,
    getList: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getList(resource, params)
        } else {
            return adminDataProvider.getList(resource, params)
        }
    },
    getOne: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getOne(resource, params)
        } else {
            return adminDataProvider.getOne(resource, params)
        }
    },
    getMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getMany(resource, params)
        } else {
            return adminDataProvider.getMany(resource, params)
        }
    },
    getManyReference: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getManyReference(resource, params)
        } else {
            return adminDataProvider.getManyReference(resource, params)
        }
    },
    create: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.create(resource, params)
        } else {
            return adminDataProvider.create(resource, params)
        }
    },
    update: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.update(resource, params)
        } else {
            return adminDataProvider.update(resource, params)
        }
    },
    updateMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.updateMany(resource, params)
        } else {
            return adminDataProvider.updateMany(resource, params)
        }
    },
    delete: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.delete(resource, params)
        } else {
            return adminDataProvider.delete(resource, params)
        }
    },
    deleteMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.deleteMany(resource, params)
        } else {
            return adminDataProvider.deleteMany(resource, params)
        }
    },
    addRegionalManager: (user: AccountUser) => {
        return request.apiFetch(API_USERS_REGIONAL_MANAGER, {method: RequestMethods.POST, body: JSON.stringify(user)})
    },
    updateUserInfo: (user: User) => {
        console.log(user)
        return request.apiFetch(API_USERS_UPDATE_INFO(user.id), {method: RequestMethods.POST, body: JSON.stringify(user)})
    },
    updateUserPassword: (password: string, id: number) => {
        return request.apiFetch(API_USERS_UPDATE_PASSWORD(id), {method: RequestMethods.POST, body: JSON.stringify({password})})
    },
    partnerConfirmPromo: (id: number, code: string) => {
        return request.apiFetch(API_PROMO_CODE_CONFIRM(id), {method: RequestMethods.POST, body: JSON.stringify({code})})
    },
    partnerDiscountEdit: (id: number, discounts: AdditionalDiscounts) => {
        return request.apiFetch(API_PARTNER_DISCOUNT_CHANGE(id), {method: RequestMethods.POST, body: JSON.stringify(discounts)})
    },
    partnerDeleteClient: (id: number, comment: string) => {
        return request.apiFetch(API_PARTNER_DELETE_CLIENT(id), {method: RequestMethods.POST, body: JSON.stringify({comment})})
    },
    partnerUpdateInfo: (value: Partner) => {
        return request.apiFetch(API_PARTNER_UPDATE_INFO(value.id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    partnerChangeCity: (id: number, city: City) => {
        return request.apiFetch(API_PARTNER_CHANGE_CITY(id), {method: RequestMethods.POST, body: JSON.stringify(city)})
    },
    partnerChangeMainDealer: (id: number, dealer: Dealer) => {
        return request.apiFetch(API_PARTNER_CHANGE_MAIN_DEALER(id), {method: RequestMethods.POST, body: JSON.stringify(dealer)})
    },
    partnerChangeDiscountVisibility: (value: Partner) => {
        return request.apiFetch(API_PARTNER_CHANGE_DISCOUNT_VISIBILITY(value.id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    partnerChangeSubDealerEnabled: (id: number, value: boolean) => {
        return request.apiFetch(API_PARTNER_CHANGE_SUB_DEALER_ENABLED(id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    partnerChangeVilatermEnabled: (id: number, value: boolean) => {
        return request.apiFetch(API_PARTNER_CHANGE_VILATERM_ENABLED(id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    partnerChangeMetalImpostEnabled: (id: number, value: boolean) => {
        return request.apiFetch(API_PARTNER_CHANGE_METAL_IMPOST_ENABLED(id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    partnerChangeGostEnabled: (id: number, value: boolean) => {
        return request.apiFetch(API_PARTNER_CHANGE_GOST_ENABLED(id), {method: RequestMethods.POST, body: JSON.stringify(value)})
    },
    ordersCopyToDev: (id: number) => {
        return request.apiFetch(API_ORDERS_COPY_TO_DEV(id), {method: RequestMethods.POST})
    },
    ordersCopyToProd: (id: number) => {
        return request.apiFetch(API_ORDERS_COPY_TO_PROD(id), {method: RequestMethods.POST})
    },
    ordersRestore: (id: number) => {
        return request.apiFetch(API_ORDERS_RESTORE(id), {method: RequestMethods.POST})
    },
    ordersRestoreStatus: (id: number) => {
        return request.apiFetch(API_ORDERS_RESTORE_STATUS(id), {method: RequestMethods.POST})
    },
    createAtWindrawAnd1c: (id: number) => {
        return request.apiFetch(API_ORDERS_CREATE_AT_WINDRAW_AND_1C(id), {method: RequestMethods.POST})
    },
    createAt1c: (id: number) => {
        return request.apiFetch(API_ORDERS_CREATE_AT_1C(id), {method: RequestMethods.POST})
    },
    booking: (id: number) => {
        return request.apiFetch(API_ORDERS_BOOKING(id), {method: RequestMethods.POST})
    },
    bookInERP: (id: number) => {
        return request.apiFetch(API_ORDERS_BOOK_IN_ERP(id), {method: RequestMethods.POST})
    },
    recalcOrder: (order: Order) => {
        return request.apiFetch(API_ORDERS_RECALC(order.id), {method: RequestMethods.POST, body: JSON.stringify(new RecalcOrderRequest(order))})
    },
    fullRecalcOrder: (id: number) => {
        return request.apiFetch(API_ORDERS_FULL_RECALC(id), {method: RequestMethods.POST})
    },
    forcedRecalcOrder: (id: number) => {
        return request.apiFetch(API_ORDERS_FORCED_RECALC(id), {method: RequestMethods.POST})
    },
    orderProductSeparationStep: (id: number, step: string) => {
        return request.apiFetch(API_ORDERS_PRODUCTS_SEPARATION_STEP(id), {method: RequestMethods.POST, body: step})
    },
    orderSetShippingDate: (id: number, date: string, force: boolean) => {
        return request.apiFetch(API_ORDERS_SET_SHIPPING_DATE(id), {method: RequestMethods.POST, body: JSON.stringify({date, force})})
    },
    orderSetShippingType: (id: number, value: string) => {
        return request.apiFetch(API_ORDERS_SET_SHIPPING_TYPE(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    orderSetShippingCost: (id: number, value: number) => {
        return request.apiFetch(API_ORDERS_SET_SHIPPING_COST(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    orderSetShippingWeekdays: (id: number, value: string) => {
        return request.apiFetch(API_ORDERS_SET_SHIPPING_WEEKDAYS(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    orderSetShippingWarehouse: (id: number, warehouseId: string) => {
        return request.apiFetch(API_ORDERS_SET_SHIPPING_WAREHOUSE(id), {
            method: RequestMethods.POST,
            body: JSON.stringify({value: warehouseId})
        })
    },
    orderSetDirectShipping: (id: number, value?: boolean) => {
        return request.apiFetch(API_ORDERS_SET_DIRECT_SHIPPING(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    orderSetDeliveryAddress: (id: number, value?: Address) => {
        return request.apiFetch(API_ORDERS_SET_DELIVERY_ADDRESS(id), {
            method: RequestMethods.POST,
            body: JSON.stringify({address: value, dadataAddress: value.dadataAddress})
        })
    },
    setOrderDiscounts: (id: number, discounts: Discount, comment: string) => {
        return request.apiFetch(API_ORDERS_SET_DISCOUNTS(id), {method: RequestMethods.POST, body: JSON.stringify({...discounts, comment})})
    },
    setOrderTags: (id: number, tags: Tags) => {
        const body = JSON.stringify({tags: Object.keys(tags).filter(tag => !!tags[tag])})
        return request.apiFetch(API_ORDERS_SET_TAGS(id), {method: RequestMethods.POST, body})
    },
    unbookAtWindraw: (id: number, reason: string) => {
        return request.apiFetch(API_ORDERS_UNBOOK_AT_WINDRAW(id), {method: RequestMethods.POST, body: reason})
    },
    unbook: (id: number) => {
        return request.apiFetch(API_ORDERS_UNBOOK(id), {method: RequestMethods.POST})
    },
    autounbook: (id: number) => {
        return request.apiFetch(API_ORDERS_AUTOUNBOOK(id), {method: RequestMethods.POST})
    },
    setGostParam: (id: number, value: boolean) => {
        return request.apiFetch(API_ORDERS_SET_GOST(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    setGostMarkParam: (id: number, value: boolean) => {
        return request.apiFetch(API_ORDERS_SET_GOST_MARK(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    setHandleParams: (id: number, values: HandleParamsRequest) => {
        return request.apiFetch(API_ORDERS_SET_HANDLE_PARAMS(id), {method: RequestMethods.POST, body: JSON.stringify(values)})
    },
    dealerUpdateInfo: (values: Dealer) => {
        return request.apiFetch(API_DEALERS_UPDATE_INFO(values.id), {method: RequestMethods.POST, body: JSON.stringify(values)})
    },
    dealerChangePaymentType: (values: Dealer) => {
        return request.apiFetch(API_DEALERS_CHANGE_PAYMENT_TYPE(values.id), {method: RequestMethods.POST, body: JSON.stringify(values)})
    },
    dealerRefund: (id: number, refund: Refund) => {
        refund.amount = refund.amount * 100
        return request.apiFetch(API_DEALERS_REFUND(id), {method: RequestMethods.POST, body: JSON.stringify(refund)})
    },
    dealerRefundOptions: (id: number) => {
        return request.apiFetch(API_DEALERS_REFUND(id), {method: RequestMethods.GET})
    },
    dealerUndoRefund: (id: number, refundId: number) => {
        return request.apiFetch(API_DEALERS_UNDO_REFUND(id, refundId), {method: RequestMethods.POST})
    },
    filialChangeVilaterm: (id: number, value: boolean) => {
        return request.apiFetch(API_FILIALS_CHANGE_VILATERM(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    filialChangeImpost: (id: number, value: boolean) => {
        return request.apiFetch(API_FILIALS_CHANGE_IMPOST(id), {method: RequestMethods.POST, body: JSON.stringify({value})})
    },
    filialChangeCity: (id: number, city: City) => {
        return request.apiFetch(API_FILIALS_CHANGE_CITY(id), {method: RequestMethods.POST, body: JSON.stringify(city)})
    },
    alertChange: (id: number, alert: Alert) => {
        return request.apiFetch(API_ALERTS_CHANGE(id), {method: RequestMethods.PUT, body: JSON.stringify(alert)})
    },
    alertCreate: (id: number, alert: Alert) => {
        return request.apiFetch(API_ALERTS, {method: RequestMethods.POST, body: JSON.stringify(alert)})
    },
    alertRestore: (id: number) => {
        return request.apiFetch(API_ALERTS_CHANGE(id), {method: RequestMethods.DELETE})
    },
    auditEventGetTypes: () => {
        return request.apiFetch(API_AUDIT_EVENTS_TYPES)
    },
    amegaEmployeeChange: (id: number, employee: AmegaEmployee) => {
        return request.apiFetch(API_AMEGA_EMPLOYEES_CHANGE(id), {method: RequestMethods.PUT, body: JSON.stringify(employee)})
    },
    amegaEmployeeAdd: (employee: AmegaEmployee) => {
        return request.apiFetch(API_AMEGA_EMPLOYEES_ADD, {method: RequestMethods.POST, body: JSON.stringify(employee)})
    },
    amegaEmployeeDelete: (id: number) => {
        return request.apiFetch(API_AMEGA_EMPLOYEES_CHANGE(id), {method: RequestMethods.DELETE})
    },
    ampqRestart: (id: number) => {
        return request.apiFetch(API_AMPQ_RESTART(id), {method: RequestMethods.POST})
    },
    restartWindrawServices: (id: number) => {
        return request.apiFetch(API_WINDRAW_SERVICES_RESTART(id), {method: RequestMethods.POST})
    },
    startWindrawOrderCreateService: (id: number) => {
        return request.apiFetch(API_WINDRAW_ORDER_CREATE_START(id), {method: RequestMethods.POST})
    },
    startWindrawDbUpdate: (id: number) => {
        return request.apiFetch(API_WINDRAW_START_DB_UPDATE(id), {method: RequestMethods.POST})
    },
    stopWindrawService: (id: number) => {
        return request.apiFetch(API_WINDRAW_STOP_SERVICE(id), {method: RequestMethods.POST})
    },
    updateModelUserParams: () => {
        return request.apiFetch(API_UPDATE_MODEL_USER_PARAMS, {method: RequestMethods.POST})
    },
    updateTemplatesUserParams: () => {
        return request.apiFetch(API_UPDATE_TEMPLATES_USER_PARAMS, {method: RequestMethods.POST})
    },
    fullRecalcUserParams: (id: number) => {
        return request.apiFetch(API_FULL_RECALC_USER_PARAMS(id), {method: RequestMethods.POST})
    },
    completeOrder: (id: number) => request.apiFetch(API_COMPLETE_ORDER(id), {method: RequestMethods.POST}),
    itemAvailableRestore: (resource: string, id: number) =>
        request.apiFetch(API_AVAILABLE_RESTORE_ITEM(resource, id), {method: RequestMethods.POST}),
    itemAvailableDelete: (resource: string, id: number) => request.apiFetch(API_RESOURCE_ITEM(resource, id), {method: RequestMethods.DELETE}),
    updateTokensForSupport: (values: ISupportAccount) =>
        request.apiFetch(API_PROFILE_UPD_TOKENS, {method: RequestMethods.POST, body: JSON.stringify(values)}),

    createRequestToSupply: values => {
        const headers = new Headers()
        headers.append('Accept', 'application/json')
        return request.apiFetch(API_REQUEST_SUPPLY, {
            method: RequestMethods.POST,
            headers: headers,
            body: values
        })
    }
}
