import {Show, ShowControllerProps} from 'react-admin'
import {Box} from '@mui/material'
import {CustomOrderTabsList} from './custom-order-tabs-list'

export const OrderItem = (props: ShowControllerProps): JSX.Element => {
    return (
        <Show component={Box} sx={{overflow: 'auto'}} {...props}>
            <CustomOrderTabsList />
        </Show>
    )
}
