import {ReactElement} from 'react'
import {Tab, TabbedShowLayout, useRecordContext} from 'react-admin'
import {OrderItemMain} from './order-item-main'
import {OrderItemDiscounts} from './order-item-discounts'
import {OrderItemConstructions} from './order-item-constructions'
import {OrderItemGoods} from './order-item-goods'
import {OrderItemRequestSupply} from './order-item-request-supply'

export const CustomOrderTabsList = (): ReactElement => {
    const record = useRecordContext()
    return (
        <TabbedShowLayout>
            <Tab label="Основные">
                <OrderItemMain />
            </Tab>
            <Tab label="Скидки">
                <OrderItemDiscounts />
            </Tab>
            <Tab label="Конструкции">
                <OrderItemConstructions calculatedByFramerPoints={record?.calculatedWithFramerPoints || record?.isPaidByFramerPoints} />
            </Tab>
            <Tab label="Доп. материалы">
                <OrderItemGoods />
            </Tab>
            {record?.windrawOrderNumber.length && (
                <Tab label="Битрикс24">
                    <OrderItemRequestSupply />
                </Tab>
            )}
        </TabbedShowLayout>
    )
}
