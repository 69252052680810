import {ReactElement, useCallback} from 'react'
import {Stack, Typography} from '@mui/material'
import {WrapperField} from 'react-admin'

export const ShowSupplyRequestField = (props: {title: string; children: ReactElement}): JSX.Element => {
    const {title, children} = props
    const isNotTextChildren = useCallback((children: ReactElement) => {
        if (children.type !== Typography) return 2
    }, [])
    return (
        <Stack direction={'row'} sx={{flexGrow: 1}}>
            <Typography sx={{width: 160, mr: 3, pt: isNotTextChildren(children)}}>{title}</Typography>
            <WrapperField>{children}</WrapperField>
        </Stack>
    )
}
