export interface ISupplyRequestComment {
    id: number
    isSend: boolean
    comment: string
    bitrixSupplyId: number
    createdAt: string
    deletedAt?: string
    postDate: string
    updatedAt: string
}

export interface ISupplyRequestsJson {
    id: number
    article: string
    createdAt: string
    createdDate: string
    deletedAt?: string
    framerComment: string
    comments?: ISupplyRequestComment[]
    productID: string
    screenshotLink: string
    status: number
    statusChangedDate: string
    supplyItemId: number
    taskId: number
    updatedAt: string
    windrawNumber: string
}

export enum SupplyRequestsStates {
    NewTask = 1,
    TaskAcceptedInCharge = 2,
    TaskInProgress = 3,
    TaskConditionallyCompleted = 4,
    TaskCompleted = 5,
    TaskPostponed = 6,
    TaskRejected = 7
}

export enum SupplyRequestsProducts {
    PVC = '11510',
    Lamination = '11512',
    Aluminium = '11514',
    Glass = '11516'
}

export class SupplyRequests {
    id: number
    article: string
    createdAt: string
    createdDate: string
    deletedAt?: string
    framerComment: string
    comments?: ISupplyRequestComment[]
    productID: string
    screenshotLink: string
    status: number
    statusChangedDate: string
    supplyItemId: number
    taskId: number
    updatedAt: string
    windrawNumber: string

    static parse(json: ISupplyRequestsJson): SupplyRequests {
        const supplyRequests = new SupplyRequests()
        Object.assign(supplyRequests, json)
        return supplyRequests
    }

    static statusList = [
        {id: SupplyRequestsStates.NewTask, name: 'Новая задача'},
        {id: SupplyRequestsStates.TaskAcceptedInCharge, name: 'Задача принята ответственным'},
        {id: SupplyRequestsStates.TaskInProgress, name: 'Задача выполняется'},
        {id: SupplyRequestsStates.TaskConditionallyCompleted, name: 'Условно завершена'},
        {id: SupplyRequestsStates.TaskCompleted, name: 'Задача выполнена'},
        {id: SupplyRequestsStates.TaskPostponed, name: 'Задача отложена'},
        {id: SupplyRequestsStates.TaskRejected, name: 'Задача отклонена ответственным'}
    ]

    static productList = [
        {id: SupplyRequestsProducts.PVC, name: 'ПВХ, фурнитура, пленка защитная для ламинации'},
        {id: SupplyRequestsProducts.Lamination, name: 'Плёнка для ламинации'},
        {id: SupplyRequestsProducts.Aluminium, name: 'Алюминий'},
        {id: SupplyRequestsProducts.Glass, name: 'Стекло, СП, пленка тонировочная, пленка защитная'}
    ]

    static getSupplyRequestStateName = (request: SupplyRequests): string => {
        switch (request.status) {
            case SupplyRequestsStates.NewTask:
                return 'Новая задача'
            case SupplyRequestsStates.TaskAcceptedInCharge:
                return 'Задача принята ответственным'
            case SupplyRequestsStates.TaskInProgress:
                return 'Задача выполняется'
            case SupplyRequestsStates.TaskConditionallyCompleted:
                return 'Условно завершена'
            case SupplyRequestsStates.TaskCompleted:
                return 'Задача выполнена'
            case SupplyRequestsStates.TaskPostponed:
                return 'Задача отложена'
            case SupplyRequestsStates.TaskRejected:
                return 'Задача отклонена ответственным'
            default:
                return 'Задача принята ответственным'
        }
    }

    static getSupplyRequestProductName = (request: SupplyRequests): string => {
        switch (request.productID) {
            case SupplyRequestsProducts.PVC:
                return 'ПВХ, фурнитура, пленка защитная для ламинации'
            case SupplyRequestsProducts.Lamination:
                return 'Плёнка для ламинации'
            case SupplyRequestsProducts.Aluminium:
                return 'Алюминий'
            case SupplyRequestsProducts.Glass:
                return 'Стекло, СП, пленка тонировочная, пленка защитная'
            default:
                return '-'
        }
    }
}
