import {useCallback, useContext, useMemo} from 'react'
import {DialogContext} from '../components/common/dialog/dialog-context'
import {useDataProvider, useGetList, useNotify, useRecordContext, useRefresh} from 'react-admin'
import {API_ADMIN, API_BACKEND, PRODUCTS_SEPARATION_STEP} from '../consts/const'
import {useToken} from './common'
import {Form, useFormik} from 'formik'
import {
    Tooltip,
    Switch,
    FormControlLabel,
    Stack,
    MenuItem,
    Typography,
    FormControl,
    InputLabel,
    Input,
    Chip,
    FormGroup,
    Checkbox,
    Button,
    Select,
    useTheme,
    FormHelperText
} from '@mui/material'
import {Order} from '../models/order'
import {HandleParamsRequest} from '../models/handle-params-request'
import {Discount} from '../models/discount'
import {Tags} from '../models/tags'
import {FormInput} from '../components/common/form-fields/form-input'
import {useRequest} from './network'
import {numbersToWeekdaysMap, toggleWeekdaysInArray} from '../consts/date'
import {MapModal} from '../components/common/map-modal/map-modal'
import {RaRecord} from 'ra-core'
import * as Yup from 'yup'
import {useMutation} from 'react-query'
import {showFieldTitle} from '../consts/styles'
import {FileUploadInput} from '../components/common/file-upload-input'
import {SupplyRequests} from '../models/supply-requests'
import {ShowSupplyRequestField} from '../components/common/show-suppy-request-field'

export const useOfferDownloadModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const accessToken = useToken()
    const record = useRecordContext()
    const submitHandler = useCallback(
        () => window.open(API_BACKEND + '/orders/' + record.id + '/offer?t=' + accessToken),
        [accessToken, record.id]
    )

    return useCallback(
        () =>
            show({
                title: 'Скачать КП',
                body: <>Вы действительно хотите скачать КП?</>,
                onSuccess: submitHandler,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [show, submitHandler]
    )
}
export const useCopyToDevModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.ordersCopyToDev(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Копировать заказ на дев',
                body: <>Скопировать все конструкции на дев?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useCopyToMeModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.ordersCopyToProd(record.id), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Копировать заказ на дев',
                body: <>Скопировать все конструкции себе?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}

export const useRestoreModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.ordersRestore(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Восстановление заказа',
                body: <>Действительно восстановить заказ?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useRestoreStatusModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.ordersRestoreStatus(record.id), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Восстановление статуса заказа',
                body: <>Действительно восстановить статус заказ?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}

export const useCreateAtWindrawAnd1c = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.createAtWindrawAnd1c(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Создать заказ в Windraw и 1С?',
                body: <>Действительно создать заказ в Windraw и 1С?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useCreateAt1c = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.createAt1c(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Создать заказ в 1С?',
                body: <>Действительно создать заказ в 1С?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useBooking = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.booking(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Забронировать',
                body: <>Забронировать заказ?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useBookInERP = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const refresh = useRefresh()
    const record = useRecordContext()
    const submitHandler = useCallback(() => dataProvider.bookInERP(record.id), [dataProvider, record.id])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})

    return useCallback(
        () =>
            show({
                title: 'Бронирование в ERP',
                body: <>Забронировать заказ в ERP?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useCreatePreorder = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const accessToken = useToken()
    const record = useRecordContext()
    const submitHandler = useCallback(() => window.open(`${API_ADMIN}/orders/${record.id}/xml?t=${accessToken}`), [accessToken, record.id])

    return useCallback(
        () =>
            show({
                title: 'Создать предварительный заказ в Windraw',
                body: <>Создать предварительный заказ в Windraw?</>,
                onSuccess: submitHandler,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [show, submitHandler]
    )
}
export const useRecalcOrder = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(() => dataProvider.recalcOrder(record), [dataProvider, record])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Пересчет заказа',
                body: <>Пересчитать заказ?</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useFullRecalcOrder = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(() => dataProvider.fullRecalcOrder(record.id), [dataProvider, record])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Действительно поставить признак полного пересчета для заказа?',
                body: (
                    <>
                        Полный пересчет - пересчет всего заказа, в случае внесения любых изменений в него. Для Перми - пересчет всех конструкций
                        при измении любой конструкции; пересчет всех допов при измении любого допа. Для Электростали - пересчет всех конструкций
                        и допов при изменении конструкции или допов.
                    </>
                ),
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useForcedRecalcOrder = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(() => dataProvider.forcedRecalcOrder(record.id), [dataProvider, record])

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Действительно поставить признак принудительного пересчета для заказа?',
                body: <>Принудительный пересчет - пересчет всего заказа, без проверки на наличие изменений</>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useProductSeparationStepModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(values => dataProvider.orderProductSeparationStep(record.id, values.step), [dataProvider, record.id])
    const {values, submitForm, handleChange} = useFormik({
        initialValues: {step: record?.productsSeparationStep ?? PRODUCTS_SEPARATION_STEP},
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Изменить количество одновременно пересчитываемых изделий для заказа',
                body: (
                    <Tooltip
                        title="Изменение количества одновременно пересчитываемых изделий в заказе может решить проблему истечения времени ожидания пересчета заказа, но только в том случае, если заказ может быть пересчитан (а не виндро зависло из-за некорректного построения)."
                        arrow>
                        <FormInput
                            size={'small'}
                            name={'step'}
                            defaultValue={values.step}
                            margin="dense"
                            autoFocus
                            label="Количество изделий"
                            type="number"
                            onChange={handleChange}
                        />
                    </Tooltip>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [handleChange, mutate, show, values.step]
    )
}
export const useOrderSetShippingDateModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(
        values => dataProvider.orderSetShippingDate(record.id, values.date, values.force),
        [dataProvider, record.id]
    )
    const {values, submitForm, handleChange} = useFormik({
        initialValues: {date: record?.shippingDate ? record?.shippingDate?.split('T')[0] : '', force: false},
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Изменение даты заказа для заказа',
                body: (
                    <Stack direction={'column'}>
                        <FormInput
                            size={'small'}
                            name={'date'}
                            defaultValue={values.step}
                            margin="dense"
                            autoFocus
                            label="Дата доставки"
                            type="date"
                            onChange={handleChange}
                        />
                        <FormControlLabel control={<Switch name={'force'} onChange={handleChange} />} label={'Без внесения в журнал'} />
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [handleChange, mutate, show, values.step]
    )
}
export const useRequestBookingCancel = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(values => dataProvider.unbookAtWindraw(record.id, values.reason), [dataProvider, record.id])
    const {values, submitForm, handleChange} = useFormik({
        initialValues: {reason: 'other'},
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Запрос на отмену бронирования',
                body: (
                    <Stack direction={'column'}>
                        <Select size={'small'} name={'reason'} defaultValue={values.reason} autoFocus onChange={handleChange}>
                            {Order.cancellationReasonList.map(i => (
                                <MenuItem key={i.id} value={i.id}>
                                    {i.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Запросить отмену заказа',
                cancelText: 'Отмена'
            }),
        [handleChange, mutate, show, values.reason]
    )
}
export const useOrderUnbook = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(() => dataProvider.unbook(record.id), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Заказ отменен в Windraw?',
                body: <></>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useOrderAutoUnbook = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(() => dataProvider.autounbook(record.id), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Запустить процесс автоматического разбронирования заказа?',
                body: <></>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, show]
    )
}
export const useSetGost = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(
        () => dataProvider.setGostParam(record.id, !record.hasGostSign),
        [dataProvider, record.hasGostSign, record.id]
    )
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: record.hasGostSign
                    ? 'Действительно удалить пользовательский параметр ГОСТ из всех заполнений заказа?'
                    : 'Действительно добавить пользовательский параметр ГОСТ во все заполнения заказа?',
                body: <></>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, record.hasGostSign, show]
    )
}
export const useSetGostMark = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(
        () => dataProvider.setGostMarkParam(record.id, !record.hasGostMarkSign),
        [dataProvider, record.hasGostMarkSign, record.id]
    )
    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: record.hasGostMarkSign
                    ? 'Действительно удалить пользовательский параметр ГОСТ-маркиратор из всех заполнений заказа?'
                    : 'Действительно добавить пользовательский параметр ГОСТ-маркиратор во все заполнения заказа?',
                body: <></>,
                onSuccess: mutate,
                successText: 'Да',
                cancelText: 'Нет'
            }),
        [mutate, record.hasGostMarkSign, show]
    )
}

export const useSetHandleParams = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(values => dataProvider.setHandleParams(record.id, values), [dataProvider, record.id])
    const {values, submitForm, handleChange, setFieldValue} = useFormik<HandleParamsRequest>({
        initialValues: new HandleParamsRequest(),
        onSubmit: submitHandler
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const menuPros = useMemo(
        () => ({
            PaperProps: {
                style: {
                    maxHeight: 220,
                    width: 250
                }
            }
        }),
        []
    )
    return useCallback(
        () =>
            show({
                title: 'Параметры ручек',
                body: (
                    <Stack direction={'column'}>
                        <Typography>
                            Введите цвет и тип ручки, а также выберите конструкции заказа, в которых необходимо произвести изменениея. Либо не
                            выбирайте конструкции, чтобы обновить их все. Оставьте значение пустым, чтобы не обновлять какой-либо параметр.
                        </Typography>
                        <FormInput
                            size={'small'}
                            defaultValue={values.handleType}
                            autoFocus
                            margin="dense"
                            label="Тип ручки"
                            type="text"
                            name={'handleType'}
                            onChange={handleChange}
                            fullWidth
                        />
                        <FormInput
                            size={'small'}
                            defaultValue={values.handleColor}
                            margin="dense"
                            label="Цвет ручки"
                            type="text"
                            name={'handleColor'}
                            onChange={handleChange}
                            fullWidth
                        />
                        <FormControl sx={{mt: 1}}>
                            <InputLabel id="mutiple-chip-label">Конструкции</InputLabel>
                            <Select
                                size={'small'}
                                labelId="mutiple-chip-label"
                                multiple
                                defaultValue={values.constructions}
                                onChange={v => setFieldValue('constructions', v.target.value)}
                                input={<Input id="select-multiple-chip" />}
                                renderValue={selected => (
                                    <div>
                                        {selected.map(value => (
                                            <Chip key={value} label={value} />
                                        ))}
                                    </div>
                                )}
                                MenuProps={menuPros}>
                                {record.constructions.map(construction => (
                                    <MenuItem key={construction.position} value={construction.position}>
                                        {`Констр. ${construction.position}`}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [handleChange, menuPros, mutate, record.constructions, setFieldValue, show, values.constructions, values.handleColor, values.handleType]
    )
}

export const useSentRequestSupply = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const theme = useTheme()
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const notify = useNotify()
    const submitHandler = useCallback(
        formData => {
            const data = new FormData()
            data.append('windrawOrderNumber', record.windrawOrderNumber)
            data.append('article', formData.article)
            data.append('productType', formData.productType)
            data.append('comment', formData.comment)
            if (formData.file) data.append('file', formData.file)

            return dataProvider.createRequestToSupply(data)
        },
        [dataProvider, record.windrawOrderNumber]
    )
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            notify('Задача успешно создана!', {type: 'success'})
            refresh()
            onClose()
        }
    })
    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: {article: '', productType: '', comment: ''},
                validationSchema: Yup.object().shape({
                    article: Yup.string().required('Обязательное поле'),
                    productType: Yup.string().required('Обязательное поле'),
                    comment: Yup.string().required('Обязательное поле')
                }),
                title: 'Создать задачу в Битрикс24',
                body: ({values, handleChange, errors, touched, handleBlur, handleSubmit, setFieldValue}) => (
                    <Form encType="multipart/form-data" onSubmit={handleSubmit}>
                        <Stack direction={'column'} spacing={3}>
                            <ShowSupplyRequestField title={'Номер заказа:'}>
                                <Typography sx={{fontWeight: 800}}>{record.windrawOrderNumber}</Typography>
                            </ShowSupplyRequestField>
                            <ShowSupplyRequestField title={'Артикул:'}>
                                <Stack sx={{flexGrow: 1}}>
                                    <FormInput
                                        defaultValue={values.article}
                                        sx={{flexGrow: 1}}
                                        error={touched.article && !!errors.article}
                                        type="text"
                                        name="article"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.article && !!errors.article && (errors.article as string)}
                                    />
                                    <Typography sx={showFieldTitle(theme)}>
                                        Если артикулов несколько, то укажите один, остальные укажите
                                        <br /> в описании или приложите скриншот
                                    </Typography>
                                </Stack>
                            </ShowSupplyRequestField>
                            <ShowSupplyRequestField title={'Продукт:'}>
                                <FormControl sx={{flexGrow: 1}}>
                                    <Select
                                        name={'productType'}
                                        defaultValue={values.productType}
                                        error={touched.productType && !!errors.productType}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        displayEmpty>
                                        {SupplyRequests.productList.map(i => (
                                            <MenuItem key={i.id} value={i.id}>
                                                {i.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.productType && !!errors.productType && <FormHelperText error>{errors.productType}</FormHelperText>}
                                </FormControl>
                            </ShowSupplyRequestField>
                            <ShowSupplyRequestField title={'Скриншот из WD:'}>
                                <FileUploadInput id={'loadFileSupply'} name={'file'} formFieldName={'file'} onChangeFn={setFieldValue} />
                            </ShowSupplyRequestField>
                            <ShowSupplyRequestField title={'Комментарий:'}>
                                <FormInput
                                    defaultValue={values.comment}
                                    sx={{flexGrow: 1}}
                                    multiline
                                    rows={'3'}
                                    error={touched.comment && !!errors.comment}
                                    name="comment"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    helperText={touched.comment && !!errors.comment && (errors.comment as string)}
                                />
                            </ShowSupplyRequestField>
                        </Stack>
                    </Form>
                ),
                onSuccess: mutate,
                successText: 'Создать задачу'
            }),
        [mutate, record.windrawOrderNumber, show, theme]
    )
}

export const useSetDiscount = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(values => dataProvider.setOrderDiscounts(record.id, values, values.comment), [dataProvider, record.id])
    const {mutate} = useMutation(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: record?.manualDiscounts,
                validationSchema: Yup.object().shape({comment: Yup.string().required('Обязательное поле')}),
                title: 'Установить скидки',
                body: ({values, handleChange, errors}) => (
                    <>
                        {Object.keys(values)
                            .filter(key => Discount.titles(key as keyof Discount))
                            .map(type => (
                                <FormInput
                                    size={'small'}
                                    sx={{m: '0.5em', width: 'calc(50% - 1em)'}}
                                    key={type}
                                    name={type}
                                    defaultValue={values[type]}
                                    autoFocus
                                    margin="dense"
                                    label={Discount.titles(type as keyof Discount)}
                                    type="number"
                                    onChange={handleChange}
                                />
                            ))}

                        <FormInput
                            error={!!errors.comment}
                            sx={{m: '0.5em', width: 'calc(100% - 1em)'}}
                            margin={'dense'}
                            size={'small'}
                            name={'comment'}
                            defaultValue={values.comment}
                            label="Комментарий"
                            onChange={handleChange}
                            fullWidth
                            helperText={errors.comment as string}
                        />
                        <FormControlLabel
                            sx={{m: '0.5em'}}
                            control={<Switch color="primary" />}
                            label="Уведомить о скидке"
                            labelPlacement="end"
                            onChange={handleChange}
                            defaultChecked={values.notification}
                            name={'notification'}
                        />
                    </>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [mutate, record?.manualDiscounts, show]
    )
}
export const useCompleteOrder = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const notify = useNotify()
    const successHandler = useCallback(() => {
        notify('Заказ был завершен успешно', {type: 'success'})
        refresh()
    }, [notify, refresh])
    // const errorHandler = useCallback((err: string) => notify(`Ошибка завершения заказа: ${err}`, {type: 'error'}), [notify])
    const submitHandler = useCallback(() => dataProvider.completeOrder(record.id), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {onSuccess: successHandler})
    return useCallback(
        () =>
            show({
                title: 'Действительно завершить заказ?',
                body: <></>,
                onSuccess: mutate,
                successText: 'Завершить',
                cancelText: 'Отмена'
            }),
        [mutate, show]
    )
}
export const useOrderSetTagsModal = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(
        async values => {
            dataProvider.setOrderTags(record.id, values)
            onClose()
        },
        [dataProvider, onClose, record.id]
    )

    const {mutate} = useRequest(submitHandler, {onSuccess: refresh})
    const initialValues = useMemo(() => Tags.fromArray(record?.tags), [record?.tags])
    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: initialValues,
                title: 'Установить теги',
                body: ({values, handleChange}) => (
                    <>
                        <FormGroup>
                            {Object.keys(values).map(tag => (
                                <FormControlLabel
                                    key={tag}
                                    control={<Checkbox checked={values[tag]} onChange={handleChange} name={tag} />}
                                    label={Tags.titles(tag)}
                                />
                            ))}
                        </FormGroup>
                    </>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [initialValues, mutate, show]
    )
}

export const useOrderSetShippingTypeModal = (): (() => void) => {
    const {show} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(values => dataProvider.orderSetShippingType(record.id, values.type), [dataProvider, record.id])
    const {values, submitForm, handleChange} = useFormik({
        initialValues: {type: record.forceShippingType},
        onSubmit: submitHandler,
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    return useCallback(
        () =>
            show({
                title: 'Установить принудительный тип доставки',
                body: (
                    <Stack direction={'column'}>
                        <Stack direction={'column'}>
                            <Select size={'small'} name={'type'} defaultValue={values.type} autoFocus onChange={handleChange} displayEmpty>
                                {Order.deliveryTypesForSelect.map(i => (
                                    <MenuItem key={i.id} value={i.id}>
                                        {i.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Stack>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [handleChange, mutate, show, values.type]
    )
}
export const useOrderSetShippingCostModal = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(value => dataProvider.orderSetShippingCost(record.id, value), [dataProvider, record.id])
    const {values, errors, submitForm, handleChange} = useFormik({
        initialValues: {cost: record.forceShippingCost},
        onSubmit: v => submitHandler(+v.cost * 100),
        enableReinitialize: true
    })
    const {mutate} = useRequest(submitForm, {onSuccess: refresh})
    const {mutate: cleared} = useRequest(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    const clearHandler = useCallback(() => cleared(null), [cleared])
    return useCallback(
        () =>
            show({
                title: 'Установить принудительную стоимость доставки',
                body: (
                    <Stack direction={'column'}>
                        <Stack direction={'column'}>
                            <FormInput
                                error={!!errors.cost}
                                sx={{width: '100%', mb: 2}}
                                margin={'dense'}
                                size={'small'}
                                name={'cost'}
                                defaultValue={values.cost !== 0 ? values.cost / 100 : 0}
                                label="Стоимость"
                                onChange={handleChange}
                                fullWidth
                                type={'number'}
                                helperText={errors.cost as string}
                            />
                            <Button
                                style={{borderRadius: 8, height: 40}}
                                sx={{mb: '12px'}}
                                variant={'outlined'}
                                onClick={clearHandler}
                                fullWidth
                                color="primary">
                                Отчистить
                            </Button>
                        </Stack>
                    </Stack>
                ),
                onSuccess: mutate,
                successText: 'Применить',
                cancelText: 'Отмена'
            }),
        [clearHandler, errors.cost, handleChange, mutate, show, values.cost]
    )
}
export const useOrderSetShippingWeekdaysModal = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(value => dataProvider.orderSetShippingWeekdays(record.id, value), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    const clearHandler = useCallback(() => mutate(null), [mutate])
    const onSuccess = useCallback((v: {weekdays: string[]}) => mutate(v.weekdays?.sort()?.join(',')), [mutate])

    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: {weekdays: record.forceWeekdays?.split(',') ?? []},
                title: 'Установить принудительные дни доставки',
                body: ({setFieldValue, values}) => {
                    const changeValue = (val: string): void => {
                        setFieldValue('weekdays', toggleWeekdaysInArray(values.weekdays, val))
                    }
                    return (
                        <Stack direction={'column'}>
                            <Stack direction={'column'}>
                                <Stack direction={'row'} sx={{mb: 2}} spacing={1}>
                                    {Object.entries(numbersToWeekdaysMap).map(([key, value]) => {
                                        return (
                                            <FormControl sx={{flex: 1}} key={key} onClick={() => changeValue(key)}>
                                                <Chip color={values.weekdays?.includes(key) ? 'primary' : 'default'} label={value} />
                                            </FormControl>
                                        )
                                    })}
                                </Stack>

                                <Button
                                    style={{borderRadius: 8, height: 40}}
                                    sx={{mb: '12px'}}
                                    variant={'outlined'}
                                    onClick={clearHandler}
                                    fullWidth
                                    color="primary">
                                    Отчистить
                                </Button>
                            </Stack>
                        </Stack>
                    )
                },
                onSuccess: onSuccess,
                successText: 'Сохранить',
                cancelText: 'Отмена'
            }),
        [clearHandler, onSuccess, record.forceWeekdays, show]
    )
}
export const useOrderSetShippingWarehouse = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const {data: warehouses} = useGetList('warehouses')
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(value => dataProvider.orderSetShippingWarehouse(record.id, value), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    const clearHandler = useCallback(() => mutate(null), [mutate])
    const onSuccess = useCallback((v: {id: number}) => mutate(v.id), [mutate])
    const {values, submitForm, setFieldValue, resetForm} = useFormik({
        initialValues: {id: record.forceWarehouseId},
        onSubmit: onSuccess,
        enableReinitialize: true
    })
    const options = useMemo(() => (warehouses ? [{id: undefined, name: 'Не указано'}, ...warehouses] : []), [warehouses])
    return useCallback(
        () =>
            show({
                title: 'Установить принудительный склад доставки',
                body: (
                    <Stack direction={'column'}>
                        <Stack direction={'column'}>
                            <Select
                                sx={{mb: 2}}
                                size={'small'}
                                name={'type'}
                                defaultValue={values?.id}
                                autoFocus
                                onChange={e => setFieldValue('id', e.target.value)}
                                displayEmpty>
                                {options.map(i => (
                                    <MenuItem key={i.id} value={i.id}>
                                        {i.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            <Button
                                style={{borderRadius: 8, height: 40}}
                                sx={{mb: '12px'}}
                                variant={'outlined'}
                                onClick={() => {
                                    clearHandler()
                                    resetForm()
                                }}
                                fullWidth
                                color="primary">
                                Отчистить
                            </Button>
                        </Stack>
                    </Stack>
                ),
                onSuccess: submitForm,
                successText: 'Применить',
                cancelText: 'Отмена'
            }),
        [clearHandler, options, resetForm, setFieldValue, show, submitForm, values?.id]
    )
}
export const useOrderSetDirectShipping = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(value => dataProvider.orderSetDirectShipping(record.id, value), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    const clearHandler = useCallback(() => mutate(null), [mutate])
    const onSuccess = useCallback((v: {direct: boolean | null}) => mutate(v.direct), [mutate])
    const options = useMemo(
        () => [
            {id: 0, value: true, name: 'Да'},
            {id: 1, value: false, name: 'Нет'}
        ],
        []
    )

    return useCallback(
        () =>
            show({
                withFormik: true,
                initialValues: {direct: record?.forceDirectShipping},
                title: 'Установить принудительную прямую доставку',
                body: ({values, setFieldValue, resetForm}) => (
                    <Stack direction={'column'}>
                        <Stack direction={'row'} sx={{mb: 2}} spacing={1}>
                            {options.map(({id, name, value}) => {
                                return (
                                    <Chip
                                        sx={{width: '100%'}}
                                        key={id}
                                        onClick={() => setFieldValue('direct', value)}
                                        color={values?.direct === value ? 'primary' : 'default'}
                                        label={name}
                                    />
                                )
                            })}
                        </Stack>
                        <Button
                            style={{borderRadius: 8, height: 40}}
                            sx={{mb: '12px'}}
                            variant={'outlined'}
                            onClick={() => {
                                clearHandler()
                                resetForm()
                            }}
                            fullWidth
                            color="primary">
                            Отчистить
                        </Button>
                    </Stack>
                ),
                onSuccess: onSuccess,
                successText: 'Применить',
                cancelText: 'Отмена'
            }),
        [clearHandler, onSuccess, options, record?.forceDirectShipping, show]
    )
}

export const useOrderSetShippingAddressModal = (): (() => void) => {
    const {show, onClose} = useContext(DialogContext)
    const dataProvider = useDataProvider()
    const record = useRecordContext()
    const refresh = useRefresh()
    const submitHandler = useCallback(value => dataProvider.orderSetDeliveryAddress(record.id, value), [dataProvider, record.id])
    const {mutate} = useRequest(submitHandler, {
        onSuccess: () => {
            refresh()
            onClose()
        }
    })
    const onSuccess = useCallback((v: {forceShippingAddress: string}) => mutate(v.forceShippingAddress), [mutate])

    return useCallback(
        () =>
            show({
                expanded: true,
                withFormik: true,
                initialValues: {forceShippingAddress: record?.forceShippingAddress},
                title: 'Установить принудительный адрес доставки',

                body: ({values, setFieldValue}) => (
                    <MapModal
                        initialValue={values.forceShippingAddress}
                        placeholder={'Изменить адрес'}
                        onSelect={v => setFieldValue('forceShippingAddress', v)}
                    />
                ),
                onSuccess: onSuccess,
                successText: 'Применить',
                cancelText: 'Отмена'
            }),
        [onSuccess, record?.forceShippingAddress, show]
    )
}

export const useOrderTotalCost = (): ((r: RaRecord) => number) => {
    return useCallback(r => {
        const factoryCost = r.totalAmegaCost
        const shippingCost = r.shippingCost ?? 0
        const shippingToFloorCost = r.shippingToFloorCost ?? 0
        const oversizedProductsTransportationServiceCost = r.oversizedProductsTransportationServiceCost ?? 0
        return factoryCost + shippingCost + shippingToFloorCost + oversizedProductsTransportationServiceCost
    }, [])
}
