import {API_ADMIN, API_BACKEND, SERVER} from './const'

export const API_MAP_UPLOAD = `${API_ADMIN}/maps/upload/yandex/geojson`
export const API_USERS_RM_REGISTER = `${API_ADMIN}/users/register/rmadmin`
export const API_USERS_UPDATE_INFO = (id: number): string => `${API_ADMIN}/users/${id}/updateinfo`
export const API_USERS_UPDATE_PASSWORD = (id: number): string => `${API_ADMIN}/users/${id}/updatepassword`
export const API_USERS_REGIONAL_MANAGER = `${API_ADMIN}/users/register/rmadmin`
export const API_PROMO_CODE_CONFIRM = (id: number): string => `${API_ADMIN}/partners/${id}/usepromo`
export const API_PARTNER_DISCOUNT_CHANGE = (id: number): string => `${API_ADMIN}/partners/${id}/setadditionaldiscounts`
export const API_PARTNER_DELETE_CLIENT = (id: number): string => `${API_ADMIN}/partners/${id}/deleteclient`
export const API_PARTNER_UPDATE_INFO = (id: number): string => `${API_ADMIN}/partners/${id}/updateinfo`
export const API_PARTNER_CHANGE_CITY = (id: number): string => `${API_ADMIN}/partners/${id}/changecity`
export const API_PARTNER_CHANGE_MAIN_DEALER = (id: number): string => `${API_ADMIN}/partners/${id}/changemaindealer`
export const API_PARTNER_CHANGE_DISCOUNT_VISIBILITY = (id: number): string => `${API_ADMIN}/partners/${id}/discountvisibility`
export const API_PARTNER_CHANGE_SUB_DEALER_ENABLED = (id: number): string => `${API_ADMIN}/partners/${id}/subdealership`
export const API_PARTNER_CHANGE_VILATERM_ENABLED = (id: number): string => `${API_ADMIN}/partners/${id}/vilaterm`
export const API_PARTNER_CHANGE_METAL_IMPOST_ENABLED = (id: number): string => `${API_ADMIN}/partners/${id}/metalimpost`
export const API_PARTNER_CHANGE_GOST_ENABLED = (id: number): string => `${API_ADMIN}/partners/${id}/gost`
export const API_ORDERS_COPY_TO_DEV = (id: number): string => `${API_ADMIN}/orders/${id}/copytodev`
export const API_ORDERS_COPY_TO_PROD = (id: number): string => `${API_ADMIN}/orders/${id}/copytoprod`
export const API_ORDERS_RESTORE = (id: number): string => `${API_ADMIN}/orders/${id}/restore`
export const API_ORDERS_RESTORE_STATUS = (id: number): string => `${API_ADMIN}/orders/${id}/restoreunbookatwindraw`
export const API_ORDERS_CREATE_AT_WINDRAW_AND_1C = (id: number): string => `${API_BACKEND}/adminorders/${id}/createatwindraw`
export const API_ORDERS_CREATE_AT_1C = (id: number): string => `${API_BACKEND}/adminorders/${id}/createat1c`
export const API_ORDERS_BOOKING = (id: number): string => `${API_BACKEND}/adminorders/${id}/book`
export const API_ORDERS_BOOK_IN_ERP = (id: number): string => `${API_BACKEND}/adminorders/${id}/bookinerp`
export const API_ORDERS_RECALC = (id: number): string => `${API_BACKEND}/orders/${id}`
export const API_ORDERS_FULL_RECALC = (id: number): string => `${API_ADMIN}/orders/${id}/batchrecalc`
export const API_ORDERS_FORCED_RECALC = (id: number): string => `${API_ADMIN}/orders/${id}/fullrecalc`
export const API_ORDERS_PRODUCTS_SEPARATION_STEP = (id: number): string => `${API_ADMIN}/orders/${id}/productsseparationstep`
export const API_ORDERS_SET_SHIPPING_DATE = (id: number): string => `${API_ADMIN}/orders/${id}/setshippingdate`
export const API_ORDERS_SET_SHIPPING_TYPE = (id: number): string => `${API_ADMIN}/orders/${id}/force/shippingtype`
export const API_ORDERS_SET_SHIPPING_COST = (id: number): string => `${API_ADMIN}/orders/${id}/force/shippingcost`
export const API_ORDERS_SET_SHIPPING_WEEKDAYS = (id: number): string => `${API_ADMIN}/orders/${id}/force/weekdays`
export const API_ORDERS_SET_SHIPPING_WAREHOUSE = (id: number): string => `${API_ADMIN}/orders/${id}/force/warehouse`
export const API_ORDERS_SET_DIRECT_SHIPPING = (id: number): string => `${API_ADMIN}/orders/${id}/force/directshipping`
export const API_ORDERS_SET_DELIVERY_ADDRESS = (id: number): string => `${API_ADMIN}/orders/${id}/force/deliveryaddress`
export const API_ORDERS_SET_DISCOUNTS = (id: number): string => `${API_ADMIN}/orders/${id}/discounts`
export const API_ORDERS_SET_TAGS = (id: number): string => `${API_ADMIN}/orders/${id}/tags`
export const API_ORDERS_UNBOOK_AT_WINDRAW = (id: number): string => `${API_ADMIN}/orders/${id}/unbookatwindraw`
export const API_ORDERS_UNBOOK = (id: number): string => `${API_ADMIN}/orders/${id}/unbook`
export const API_ORDERS_AUTOUNBOOK = (id: number): string => `${API_ADMIN}/orders/${id}/autounbook`
export const API_ORDERS_SET_GOST = (id: number): string => `${API_ADMIN}/orders/${id}/setgostparam`
export const API_ORDERS_SET_GOST_MARK = (id: number): string => `${API_ADMIN}/orders/${id}/setgostmarkparam`
export const API_ORDERS_SET_HANDLE_PARAMS = (id: number): string => `${API_ADMIN}/orders/${id}/sethandleparams`
export const API_DEALERS_UPDATE_INFO = (id: number): string => `${API_ADMIN}/dealers/${id}/updateinfo`
export const API_DEALERS_CHANGE_PAYMENT_TYPE = (id: number): string => `${API_ADMIN}/dealers/${id}/paymenttype`
export const API_DEALERS_REFUND = (id: number): string => `${API_ADMIN}/dealers/${id}/refund`
export const API_DEALERS_UNDO_REFUND = (id: number, refundId: number): string => `${API_ADMIN}/dealers/${id}/refund/${refundId}/undo`
export const API_FILIALS_CHANGE_VILATERM = (id: number): string => `${API_ADMIN}/filials/${id}/changevilaterm`
export const API_FILIALS_CHANGE_IMPOST = (id: number): string => `${API_ADMIN}/filials/${id}/changeimpost`
export const API_FILIALS_CHANGE_CITY = (id: number): string => `${API_ADMIN}/filials/${id}/changecity`
export const API_ALERTS_CHANGE = (id: number): string => `${API_ADMIN}/alerts/${id}`
export const API_ALERTS = `${API_ADMIN}/alerts`
export const API_AUDIT_EVENTS_TYPES = `${API_ADMIN}/adminauditevents/types`
export const API_AMEGA_EMPLOYEES_CHANGE = (id: number): string => `${API_ADMIN}/amegaemployees/${id}`
export const API_AMEGA_EMPLOYEES_ADD = `${API_ADMIN}/amegaemployees`
export const API_AMPQ_RESTART = (id: number): string => `${API_BACKEND}/amqphandlers/${id}/restart`
export const API_WINDRAW_SERVICES_RESTART = (id: number): string => `${API_ADMIN}/windrawservices/stopcalcs/${id}`
export const API_WINDRAW_ORDER_CREATE_START = (id: number): string => `${API_ADMIN}/windrawservices/startcreate/${id}`
export const API_WINDRAW_START_DB_UPDATE = (id: number): string => `${API_ADMIN}/windrawservices/startdb/${id}`
export const API_WINDRAW_STOP_SERVICE = (id: number): string => `${API_ADMIN}/windrawservices/${id}/stop`
export const API_UPDATE_MODEL_USER_PARAMS = `${API_BACKEND}/modelparams/updatestorage`
export const API_UPDATE_TEMPLATES_USER_PARAMS = `${API_BACKEND}/templates/updatestorage`
export const API_FULL_RECALC_USER_PARAMS = (id: number): string => `${API_ADMIN}/orders/setbatchrecalc/${id}`
export const API_COMPLETE_ORDER = (id: number): string => `${API_ADMIN}/orders/${id}/forcecomplete`
export const API_AVAILABLE_RESTORE_ITEM = (resource: string, id: number): string => `${API_ADMIN}/${resource}/${id}/restore`
export const API_RESOURCE_ITEM = (resource: string, id: number): string => `${API_ADMIN}/${resource}/${id}`
export const API_PROFILE = `${API_ADMIN}/profile`
export const API_LOGIN = `${SERVER}/login`
export const API_REQUEST_SUPPLY = `${API_ADMIN}/supplies`

export const API_PROFILE_UPD_TOKENS = `${API_PROFILE}/updtokens`
