import {ChangeEvent, useCallback, useRef, useState} from 'react'
import {FormikErrors, FormikValues} from 'formik'
import {truncateFilename} from '../../../helpers/name-file-truncate'
import {ButtonRemoved, InputHidden, LabelInput, Wrapper} from './styled'

interface IFileUploadInput {
    id: string
    name: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChangeFn: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<FormikValues>>
    formFieldName: string
}

export const FileUploadInput = (props: IFileUploadInput): JSX.Element => {
    const {id, name, formFieldName, onChangeFn} = props
    const [selectedFile, setSelectedFile] = useState(null)
    const inputRef = useRef<HTMLInputElement>(null)

    const handleFileChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>): void => {
            const file = evt.target.files[0]
            if (file) {
                setSelectedFile(file)
                onChangeFn(formFieldName, file)
            }
        },
        [formFieldName, onChangeFn]
    )

    const handleRemoveFile = useCallback(() => {
        setSelectedFile(null)
        if (inputRef.current) inputRef.current.value = ''
        onChangeFn(formFieldName, null)
    }, [formFieldName, onChangeFn])

    return (
        <div>
            <InputHidden name={name} id={id} type="file" ref={inputRef} onChange={handleFileChange} />
            {!selectedFile && (
                <LabelInput tabIndex={0} htmlFor={id}>
                    + Добавить изображение
                </LabelInput>
            )}
            {selectedFile && (
                <Wrapper>
                    <span>{truncateFilename(selectedFile.name)}</span>
                    <ButtonRemoved onClick={handleRemoveFile}>Удалить</ButtonRemoved>
                </Wrapper>
            )}
        </div>
    )
}
