import {ReactElement, useCallback} from 'react'
import {useGetList, useRecordContext} from 'react-admin'
import {useNavigate} from 'react-router'
import {styled} from '@mui/material'

const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    color: #0075ff;
    background-color: #f6f6f6;
    border-radius: 8px;
    gap: 5px;
    margin-top: 8px;
    cursor: pointer;
`
const Text = styled('span')`
    font-size: 12px;
    font-weight: 500;
`

export const RequestSupplyLinkList = (): ReactElement => {
    const record = useRecordContext()
    const navigate = useNavigate()
    const {data} = useGetList('supplies', {filter: {windrawNumber: record?.windrawOrderNumber}})
    const onClickHandle = useCallback((id: number) => () => navigate(`/supplies/${id}/show`), [navigate])
    if (!record) return null
    return (
        <>
            {data?.map(item => (
                <Wrapper key={item.id} onClick={onClickHandle(item.id)}>
                    <Text>{`Задача ${item.taskId} ${item.status === 5 ? 'завершена' : 'создана'}`}</Text>
                </Wrapper>
            ))}
        </>
    )
}
