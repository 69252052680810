import * as React from 'react'
import {memo, useMemo} from 'react'
import get from 'lodash/get'
import Link from '@mui/material/Link'
import {useRecordContext} from 'ra-core'
import {sanitizeFieldRestProps, TextFieldProps} from 'react-admin'
import Typography from '@mui/material/Typography'

interface ICustomLinkFieldProps extends TextFieldProps {
    source?: string
    emptyText?: string
    className?: string
    textContent: string
}
export const CustomLinkField = memo((props: ICustomLinkFieldProps) => {
    const {className, source, textContent, emptyText, ...rest} = props
    const record = useRecordContext(props)
    const value = useMemo(() => {
        const sourceValue = get(record, source)
        const value = sourceValue != null && typeof sourceValue !== 'string' ? JSON.stringify(sourceValue) : sourceValue || emptyText
        return value.replace(/[,| ]/gm, ', ')
    }, [emptyText, record, source])

    if (value === '-')
        return (
            <Typography component="span" variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
                -
            </Typography>
        )

    return (
        <Link href={value} variant="body2" className={className} {...sanitizeFieldRestProps(rest)}>
            {textContent}
        </Link>
    )
})

// what? TypeScript loses the displayName if we don't set it explicitly
CustomLinkField.displayName = 'CustomLinkField'
