import {Card, Grid} from '@mui/material'
import {CardHead} from '../../../components/common/card-head/card-head'
import {OrderSendRequestToSupply} from './components/order-send-request-to-supply/order-send-request-to-supply'
import {useRecordContext} from 'react-admin'
import {ReactElement} from 'react'
import {RequestSupplyLinkList} from './components/request-supply-link-list'

export const OrderItemRequestSupply = (): ReactElement => {
    const record = useRecordContext()
    if (!record) return null
    return (
        <Grid container spacing={2}>
            <Grid item xs={3}>
                <Card sx={{p: 2, mb: 2}}>
                    <CardHead title={'Битрикс24'} />
                    <OrderSendRequestToSupply />
                    <RequestSupplyLinkList />
                </Card>
            </Grid>
        </Grid>
    )
}
