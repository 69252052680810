import {Box, Card, Divider, Grid} from '@mui/material'
import {Show} from 'react-admin'
import {CardHead} from '../../../components/common/card-head/card-head'
import {ShowField} from '../../../components/common/card-text-field/show-field'
import {FunctionalShowField} from '../../../components/common/functional-show-field/functional-show-field'
import {SupplyRequests} from '../../../models/supply-requests'
import {CardHeadSubtitle} from '../../../components/common/card-head-subtitle/card-head-subtitle'
import {SupplyRequestComments} from './components/supply-request-comments'
import {SupplyRequestTitle} from './components/supply-request-title'

export const SupplyRequestItem = (): JSX.Element => {
    return (
        <Show sx={{overflow: 'auto'}} component={Box} title={<SupplyRequestTitle />}>
            <Grid container spacing={2} columns={{xs: 4, sm: 8, md: 12}}>
                <Grid item xs={4.5}>
                    <Card sx={{p: 2, mb: 2}}>
                        <CardHead title={'Запрос'} />
                        <ShowField title={'Номер задачи в Битрикс24'} sx={{mb: 1}} source={'taskId'} />
                        <ShowField type={'date'} title={'Дата создания задачи'} sx={{mb: 1}} source={'createdAt'} />
                        <FunctionalShowField
                            title={'Текущий статус задачи'}
                            sx={{mb: 1}}
                            render={SupplyRequests.getSupplyRequestStateName}
                            source={'status'}
                        />
                        <ShowField title={'Комментарий к задаче'} sx={{mb: 1}} source={'framerComment'} />
                        <ShowField type={'date'} title={'Время обновления статуса'} sx={{mb: 1}} source={'statusChangedDate'} />
                        <Divider sx={{mt: '16px', mb: '32px'}} />
                        <CardHeadSubtitle title={'О запросе'} />
                        <ShowField title={'Номер заказа'} sx={{mb: 1}} source={'windrawNumber'} />
                        <ShowField title={'Артикул'} sx={{mb: 1}} source={'article'} />
                        <FunctionalShowField
                            title={'Продукт'}
                            sx={{mb: 1}}
                            render={SupplyRequests.getSupplyRequestProductName}
                            source={'productID'}
                        />
                        <ShowField type={'link'} title={'Скриншот из WD'} source={'screenshotLink'} textContent={'Скачать скриншот'} />
                        <Divider sx={{mt: '16px', mb: '32px'}} />
                        <CardHeadSubtitle title={'Комментарии'} />
                        <SupplyRequestComments />
                    </Card>
                </Grid>
            </Grid>
        </Show>
    )
}
