import {DateField, EmailField, NumberField, ReferenceField, TextField} from 'react-admin'
import {Stack, SxProps, Theme, Typography, useTheme} from '@mui/material'
import {Fragment, useMemo} from 'react'
import {showFieldTitle, showFieldValue} from '../../../consts/styles'
import {CustomEmailField} from '../custom-email-field/custom-email-field'
import {CustomTextField} from '../custom-text-field/custom-text-field'
import {CustomLinkField} from '../custom-link-field/custom-link-field'

interface ICardTextFieldProps {
    title: string
    source?: string
    color?: string
    type?: 'email' | 'default' | 'date' | 'number' | 'custom-email' | 'custom-text' | 'link'
    sx?: SxProps<Theme>
    reference?: string
    referenceSource?: string
    link?: string
    options?: object
    direction?: 'row' | 'column'
    textContent?: string
}

export const ShowField = (props: ICardTextFieldProps): JSX.Element => {
    const {
        title,
        source,
        color = 'transparent',
        type = 'default',
        sx,
        referenceSource,
        reference,
        link,
        options,
        textContent,
        direction = 'row'
    } = props
    const theme = useTheme()
    const valueProps = {emptyText: '-', sx: showFieldValue, source, showTime: true, options, textContent}
    const Component = useMemo(() => {
        switch (type) {
            case 'email':
                return EmailField
            case 'date':
                return DateField
            case 'number':
                return NumberField
            case 'custom-email':
                return CustomEmailField
            case 'custom-text':
                return CustomTextField
            case 'link':
                return CustomLinkField
            default:
                return TextField
        }
    }, [type])
    const Wrapper = reference ? ReferenceField : Fragment

    return (
        <Stack
            alignItems={direction === 'column' ? 'flex-start' : 'center'}
            sx={{...sx, background: color, borderRadius: '6px', p: '6.5px 12px'}}
            direction={direction}
            justifyContent={'space-between'}>
            <Typography sx={showFieldTitle(theme)}>{title}</Typography>
            <Wrapper emptyText={'-'} sx={{lineHeight: 1}} reference={reference} source={referenceSource} link={link}>
                <Component {...valueProps} />
            </Wrapper>
        </Stack>
    )
}
