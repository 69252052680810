import {DateInput, Filter, SelectInput, TextInput} from 'react-admin'
import {SupplyRequests} from '../../../models/supply-requests'

export const SupplyRequestListFilters = (props): JSX.Element => {
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn />
            <SelectInput label="Статус" source="status" emptyText="Все" alwaysOn choices={SupplyRequests.statusList} />
            <DateInput label="Дата оформления от" source="dateFrom" alwaysOn />
            <DateInput label="Дата оформления до" source="dateTo" alwaysOn />
        </Filter>
    )
}
