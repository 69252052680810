import styled, {css} from 'styled-components'

export const baseStyles = css`
    display: flex;
    align-items: center;
    color: #0075ff;
    height: 55px;
    border-radius: 12px;
    padding: 0 16px;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    transition: background-color 0.4s ease-in-out;
`

export const LabelInput = styled.label`
    box-sizing: border-box;
    ${baseStyles};
    &:hover {
        background-color: #f6f6f6;
    }
`
export const InputHidden = styled.input`
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
    &:focus + ${LabelInput} {
        background-color: #f6f6f6;
        outline: 1px solid #0075ff;
    }
`

export const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    height: 55px;
`

export const ButtonRemoved = styled.button`
    ${baseStyles};
    border: none;
    &:hover,
    &:focus {
        background-color: #f6f6f6;
    }
    &:focus {
        outline: 1px solid #0075ff;
    }
`
