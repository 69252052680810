import {Button} from '@mui/material'
import {useSentRequestSupply} from '../../../../../hooks/orders'

export const OrderSendRequestToSupply = (): JSX.Element => {
    const showModal = useSentRequestSupply()
    return (
        <Button fullWidth variant="contained" color="inherit" onClick={showModal}>
            <>Создать запрос в Снабжение</>
        </Button>
    )
}
