export const truncateFilename = (filename: string): string => {
    // Разделяем имя файла и расширение
    const parts = filename.split('.')
    const ext = parts.pop() // Получаем расширение
    const name = parts.join('.') // Объединяем оставшиеся части в имя

    // Проверяем длину имени файла без расширения
    if (name.length > 20) {
        // Обрезаем имя до 20 символов и добавляем три точки
        const truncatedName = name.slice(0, 17) + '...'
        // Возвращаем новое имя файла с расширением
        return `${truncatedName}.${ext}`
    }

    // Если длина имени меньше или равна 20, возвращаем исходное имя файла
    return filename
}
