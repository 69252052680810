const addLeadingZero = (number: number): string | number => {
    return number < 10 ? `0${number}` : number
}

export function formatDate(isoDateString: string): string {
    const date = new Date(isoDateString)

    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const monthNames = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря']

    return `${day} ${monthNames[month]} ${year}, ${addLeadingZero(hours)}:${addLeadingZero(minutes)}`
}
