import {ISupplyRequestComment} from '../../../../models/supply-requests'
import {Stack, Typography, useTheme} from '@mui/material'
import {memo} from 'react'
import {useRecordContext} from 'react-admin'
import {showFieldTitle, showFieldValue} from '../../../../consts/styles'
import {formatDate} from '../../../../helpers/date-format'

const CommentRequest = ({comment}: {comment: ISupplyRequestComment}): JSX.Element => {
    const theme = useTheme()
    return (
        <Stack
            alignItems={'flex-start'}
            sx={{background: theme.palette.grey['200'], borderRadius: '6px', p: '6.5px 12px', mb: '8px'}}
            direction={'column'}>
            <Typography sx={{...showFieldTitle(theme), mb: '16px'}}>{formatDate(comment.postDate)}</Typography>
            <Typography sx={{...showFieldValue}}>{comment.comment}</Typography>
        </Stack>
    )
}

export const SupplyRequestComments = memo((): JSX.Element => {
    const theme = useTheme()
    const record = useRecordContext()
    if (!record) return null
    const content = record?.comments?.map(comment => <CommentRequest key={comment.id} comment={comment} />)

    const noComments = <Typography sx={{...showFieldTitle(theme)}}>Комментариев нет</Typography>
    return <>{record.comments.length ? content : noComments}</>
})

SupplyRequestComments.displayName = 'SupplyRequestComments'
